.lang {
    display: none;
}

.active-lang-sv .lang-sv {
    display: inline;
}

.active-lang-da .lang-da {
    display: inline;
}

.active-lang-nb .lang-nb {
    display: inline;
}

.active-lang-en .lang-en {
    display: inline;
}