.default-hidden {
  display: none;
}

form {
  margin: 0;
}

.centered {
  margin-left: auto;
  margin-right: auto;
}

.text-center {
  text-align: center;
}

.desktop-show {
  display: none;
}

@media (min-width: 750px) {
  .desktop-show {
      display: block;
  }

  span.desktop-show {
      display: inline;
  }
}

.is-android .is-android-show {
  display: inherit;
}

.is-android .is-android-hide {
  display: none;
}

.is-ios .is-ios-hide {
  display: none;
}

.is-not-ios .is-not-ios-show {
  display: block;
}

.has-error .has-error-hide, .has-error .is-android-show.has-error-hide {
  display: none;
}

.has-error .has-error-show {
  display: block;
}

.has-error span.has-error-show {
  display: inline;
}
