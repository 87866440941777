

.broker header, .broker #headline {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  font-family: "Roboto Slab";
}

.broker h1#headline {
  margin-top: 80px;
  margin-bottom: 30px;
  max-width: 400px;
  font-size: 34px;
}

@media (max-height: 812px) {
  .broker h1#headline {
      margin-top: 30px;
  }
}

h1.centered, a.centered {
  text-align: center;
}

