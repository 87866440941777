.broker #description {
  max-width: 400px;
  padding: 30px 30px 0px 30px;
  box-sizing: border-box;
}

  .broker #description p {
      margin: 0px 0px 30px 0px;
  }

.broker footer, .broker #description {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  font-family: "Roboto Slab";
}