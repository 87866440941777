.broker .frame {
  background-color: #FFF;
  box-sizing: border-box;
  min-height: 100%;
  width: 100%;
}

@media (min-width: 750px) {
  .broker .frame {
      max-width: 400px;
      width: auto;
      min-height: auto;
      overflow-y: auto;
  }
}